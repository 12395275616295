@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;600&display=swap");

#root {
  background-color: rgb(16, 31, 41);
  font-family: "Roboto Mono", monospace;
  color: rgb(240, 240, 231);
}

*:focus {
  outline: 1px solid #6adcff;
}

a {
  color: rgb(67, 166, 233) !important;
}

h1 {
  font-size: 40px !important;
}

h1,
h2,
h3,
h4 {
  font-family: "Roboto Mono", monospace !important;
}

h4 {
  line-height: 0.1 !important;
}

.navItem {
  font-family: "Roboto Mono", monospace !important;
  margin: 0 !important;
  padding-top: 20px;
  padding-bottom: 15px;
}

.navHeader {
  font-family: "Roboto Mono", monospace !important;
  padding-left: 15px !important;
}

/* *********banner */
.bannerImage {
  position: relative;
  width: 100%;
}
.bodyHeader {
  text-align: center;
  padding-top: 30px;
  color: rgb(255, 196, 0);
  /* color: rgb(155, 0, 34); */
}
.bannerText {
  position: absolute;
  top: 20%;
  left: 10%;
  z-index: 1;
}

.projData a {
  font-family: "Roboto Mono", monospace;
  font-size: 12px;
}
.icon {
  margin-right: auto;
}
.imageCol {
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5vw;
}
.copyCol {
  margin-right: auto;
  margin-left: auto;
}
.urlMainLink {
  display: inline;
  padding-left: 30px;
  font-weight: 600;
  padding-top: 30px;
}
.rectContainer {
  font-family: "Roboto Mono", monospace !important;
  padding-top: 50px;
}
.landscapeImage {
  max-height: 500px;
  height: auto;
  width: auto;
}
.squareCard {
  font-family: "Roboto Mono", monospace !important;
  padding: 30px;
  padding-bottom: 50px;
  padding-top: 50px;
}
.squareImage {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}
.popStyle {
  cursor: pointer;
}
.projNotes {
  font-weight: 600;
}
/* ***********THINGS */
.imageCard {
  width: auto;
  max-height: 300px;
  padding: 0 15px 0;
}
.imageGrid {
  padding: 10px;
}
/* ************ABOUT */
.profile {
  font-family: "Roboto Mono", monospace !important;
  padding: 40px;
}
.profilePic {
  width: 20vw;
  height: auto;
}
.devSkills {
  background-color: rgb(29, 54, 71);
}
.addSkills {
  background-color: rgb(29, 54, 71);
}

/* **********FOOTER */
footer {
  margin-top: 150px;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 60px;
  background-color: rgb(155, 0, 34);
}
.linkedinIcon {
  width: 30px;
}
.linkLinkedIn {
  margin-left: 30px;
  position: relative;
  display: inline-block;
}
.linkLinkedIn:after {
  content: "";
  position: absolute;
  border: rgb(240, 240, 231) solid 1px;
  border-radius: 10px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  transition: all 200ms ease-in-out;
}
.linkLinkedIn:hover:after {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  opacity: 1;
}
.octocatIcon {
  width: 30px;
  position: relative;
}
.linkGitHub {
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
}
.linkGitHub:after {
  content: "";
  position: absolute;
  border: rgb(240, 240, 231) solid 1px;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
  transition: all 200ms ease-in-out;
}
.linkGitHub:hover:after {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  opacity: 1;
}

.resumeButton {
  font-family: "Roboto Mono", monospace !important;
  /* color: rgb(240, 240, 231) !important; */
}
.cardFooter {
  text-align: center;
}
.copyright {
  text-align: center;
  vertical-align: middle;
}
/* modal styling */
.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 85%;
  padding: 10px 5px;
  margin: auto;
  font-family: "Roboto Mono", monospace;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  opacity: 0.7;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff !important;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.popup-content {
  border-radius: 4px;
  border: 1px solid #cfcece;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

/* animation */
@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
